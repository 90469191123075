/*
 * @Author: qiankun
 * @LastEditors: qiankun
 */

import { defineStore } from "pinia";
export const useScreenStore = defineStore("screen", {
  state: () => ({
    currentAmr: null,
    amrRealTimeList: [],
    amrsMap: {},
    isFulling: false, // 标记是否全屏
    isCameraFull: false,
    cameraIsOpen: false,
  }),
  actions: {
    setFull(isFulling) {
      this.isFulling = isFulling;
    },
    setCameraFull(isCameraFull) {
      this.isCameraFull = isCameraFull;
    },
    setAmr(amr) {
      this.currentAmr = amr;
    },
    setAmrStatus(status) {
      this.currentAmr.status = status;
    },
    setAmrList(amrs) {
      this.amrRealTimeList = amrs || [];
      this.amrsMap = {};
      if (amrs && amrs.length > 0) {
        amrs.map((item) => {
          this.amrsMap[item.snCode] = item;
        });
      }
    },
    setCameraIsOpen(isOpen) {
      console.log(isOpen);
      this.cameraIsOpen = isOpen;
    },
  },
});
