/*
 * @Author: fangjun
 * @Date: 2023-01-30 08:45:20
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2024-09-12 14:40:59
 * @FilePath: \src\api\common.js
 */
import request from "@/utils/axiosReq";
import router from "@/router";
import { useUserStore } from "../store/modules/user";
import { parameterMapping } from "@/utils/index";
import { getToken } from "@/utils/auth";
// websocket接口url
export const cloudControlWebSocketUrl = `${
  location.protocol === "https:" ? "wss" : "ws"
}://${window.location.host}/wsapi/robion/websocket/display`;

export function switchCameraMode(query) {
  return request({
    url: "/home/camera/switchMode",
    method: "post",
    data: query,
  });
}

export const rtspUrl = `webrtc://${import.meta.env.VITE_APP_VIDEO_URL}/live`;
export const micrPhoneUrl = `webrtc://${
  import.meta.env.VITE_APP_VIDEO_URL
}/voice`;

//发送埋点信息
export function sendTrackInfo(obj) {
  let whiteList = ["/login"];
  if (whiteList.includes(router.currentRoute.value.path)) return;
  const userStore = useUserStore();
  obj = obj || { eventType: "1" };
  let matched = router.currentRoute.value.matched.filter(
    (item) => item.meta && item.meta.title
  );
  let levelList = matched.filter(
    (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
  );
  let pageName = levelList.map((level) => level.meta.title)?.join("/");
  obj = {
    userId: userStore.user.id,
    time: new Date().getTime(),
    platform: "-2",
    url: router.currentRoute.value.path,
    pageName,
    token: import.meta.env.VITE_APP_TOKEN_BEAR + " " + getToken(),
    ...obj,
  };

  let url = "/api/console/eventTracking/eventTracking.gif";
  let params = parameterMapping(url, obj);
  let tempImage = new Image();
  tempImage.crossOrigin = "Anonymous";
  tempImage.src = params;
  tempImage = null;
}

//日志记录埋点
export function OperLog(query) {
  query.systemModel = "pjicloud-robion";
  query.requestMethod = "POST";
  query.operatorType = 1;
  query.operUrl = "/system/operlog/addLog";
  return request({
    url: query.operUrl,
    method: "post",
    data: query,
  });
}

// 行为记录上报
export function BehaviorLog(query) {
  return request({
    url: "/console/behavior/save",
    method: "post",
    data: query,
  });
}

/**
 * @description: 查询机器人型号列表
 * @param {*} data
 * @return {*}
 */
export function apiGetAmrModelSelect(data) {
  return request({
    url: "/robion/amr/listCategory",
    method: "get",
    data,
  });
}

/**
 * @description:获取获取机器人语言类型
 * @param {*} query
 * @return {*}
 */
export function apiGetAudioLanguageType(data) {
  return request({
    url: "/inspect/robot/language/getInfo",
    method: "get",
    data,
  });
}
