<!--
 * @Author: fangjun
 * @Date: 2022-08-05 14:36:21
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-02-23 10:23:42
 * @FilePath: \src\views\userCenter\index.vue
-->
<template>
  <el-dialog
    v-model="dialog.visible"
    title="个人中心"
    width="700px"
    class="user-center"
    :close-on-click-modal="false"
    :append-to-body="true"
    center
    @open="onOpen"
  >
    <div class="user-box" v-loading="loading">
      <div>
        <el-form ref="form" :model="formParams" :rules="rules" @submit.prevent>
          <div class="font-bold text-xl">用户信息</div>
          <div class="flex my-5 ml-8 text-lg">
            <div
              class="mr-5 relative overflow-hidden h-fit rounded-full cursor-pointer"
              style="height: 120px"
            >
              <el-upload
                ref="avatarUpload"
                class="user-avatar"
                :with-credentials="true"
                :action="uploadURL"
                :data="{ moduleName: 'userCenter' }"
                :headers="headers"
                :accept="acceptAvatar.format"
                :limit="1"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
              >
                <el-avatar
                  :size="120"
                  :src="currentAvatar"
                  @error="
                    () => {
                      return true;
                    }
                  "
                >
                  <img src="~@/assets/images/avatar-error.png" alt="" />
                </el-avatar>
                <div
                  class="avatar-modify bottom-0 absolute w-full text-center bg-gray-200 text-gray-600"
                >
                  点击修改
                </div>
              </el-upload>
            </div>
            <div>
              <div class="flex items-center">
                <div>
                  <span class="title font-bold">姓名</span>
                  ：
                </div>
                <div>
                  <div class="user-name" v-show="!isShowInputName">
                    <div class="show-name" :title="formParams.currentName">
                      {{ formParams.currentName }}
                    </div>
                    <el-button type="primary" link @click="showInputName">
                      <el-icon :size="16">
                        <edit-pen />
                      </el-icon>
                    </el-button>
                  </div>
                  <el-form-item
                    class="w-60"
                    prop="userName"
                    v-show="isShowInputName"
                  >
                    <div class="flex">
                      <el-input
                        ref="input-name"
                        v-model="formParams.userName"
                        maxlength="25"
                        show-word-limit
                        @blur="editInputName"
                      ></el-input>
                      <el-button type="primary" link @click="showInputName">
                        <el-icon :size="16">
                          <check />
                        </el-icon>
                      </el-button>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="my-4">
                <span class="title font-bold">手机号</span>
                ：
                <span>{{ formParams.phoneNum }}</span>
              </div>
              <div>
                <span class="title font-bold">角色</span>
                ：
                <span>{{ formParams.roleName }}</span>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="mt-5">
        <div class="font-bold text-xl">企业信息</div>
        <table class="mt-5 h-36 ml-8 text-lg">
          <tr>
            <td>
              <span class="font-bold">公司名称</span>
              ：
            </td>
            <td>
              <div class="user-name" v-show="!isShowInputBusinessName">
                <div class="show-name" :title="formParams.businessName">
                  {{ formParams.businessName }}
                </div>
                <el-button
                  type="primary"
                  link
                  @click="showInputBusinessName"
                  v-hasPermi="['globalConfig:businessName:edit']"
                >
                  <el-icon :size="16">
                    <edit-pen />
                  </el-icon>
                </el-button>
              </div>
              <el-form-item
                class="w-60"
                prop="businessName"
                v-show="isShowInputBusinessName"
              >
                <div class="flex">
                  <el-input
                    ref="input-business-name"
                    v-model.trim="formParams.businessName"
                    maxlength="50"
                    show-word-limit
                    @blur="editInputBusinessName"
                  ></el-input>
                  <el-button type="primary" link @click="showInputBusinessName">
                    <el-icon :size="16">
                      <check />
                    </el-icon>
                  </el-button>
                </div>
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>
              <span class="font-bold">详细地址</span>
              ：
            </td>
            <td>
              <div
                class="w-96 text-ellipsis overflow-hidden whitespace-nowrap"
                :title="formParams.address"
              >
                {{ formParams.address }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="font-bold">场景类型</span>
              ：
            </td>
            <td>{{ formParams.industryTypeName }}</td>
          </tr>
        </table>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getCurrentInstance, reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { EditPen, Check } from "@element-plus/icons-vue";
import { apiUpdateUser } from "@/api/userMgt";
import { checkUserName } from "@/utils/validate";
import { getToken } from "@/utils/auth";
import { useUserStore } from "@/store/modules/user";

export default {
  name: "UserCenter",
  props: ["dialog"],
  components: { EditPen, Check },
  setup(props, context) {
    const state = reactive({
      loading: false,
      formParams: {
        userId: "",
        currentName: "Jack",
        userName: "Jack",
        phoneNo: "",
        avatar: "",
        businessNameBake: "",
        businessName: "",
        address: "",
        industryTypeName: "",
        roleName: "",
      },
      currentAvatar: "",
      backupAvatar: "",
      isShowInputName: false,
      isShowInputBusinessName: false,
      uploadURL: import.meta.env.VITE_APP_IMAGE_UPLOAD_API,
      headers: {
        Authorization: import.meta.env.VITE_APP_TOKEN_BEAR + " " + getToken(),
      },
      acceptAvatar: {
        format: ".png,.jpg,.jpeg",
        types: ["image/png", "image/jpg", "image/jpeg"],
        size: 10, //MB
      },
      rules: {
        userName: [
          { required: true, message: "姓名不能为空", trigger: "change" },
          { validator: checkUserName, trigger: "change" },
        ],
        businessName: [
          { required: true, message: "公司名称不能为空", trigger: "change" },
          { validator: checkUserName, trigger: "change" },
        ],
      },
    });
    const userStore = useUserStore();
    let { dialog } = toRefs(props);
    let { proxy } = getCurrentInstance();
    let { emit } = context;

    function showInputName() {
      state.isShowInputName = true;
      state.formParams.userName = state.formParams.currentName;
      proxy.$refs["input-name"].focus();
    }
    function editInputName() {
      if (
        state.formParams.userName === "" ||
        state.formParams.userName === state.formParams.currentName
      ) {
        state.isShowInputName = false;
        state.formParams.userName = state.formParams.currentName;
      } else {
        proxy.$refs["form"].validate((valid) => {
          state.isShowInputName = false;
          updateUser();
        });
      }
    }

    function showInputBusinessName() {
      state.isShowInputBusinessName = true;
      proxy.$refs["input-business-name"].focus();
    }
    function editInputBusinessName() {
      if (state.formParams.businessName === "") {
        state.isShowInputBusinessName = false;
        state.formParams.businessName = state.formParams.businessNameBake;
      } else {
        proxy.$refs["form"].validate((valid) => {
          state.isShowInputBusinessName = false;
          updateUser();
        });
      }
    }

    function loadUserInfo() {
      let {
        id,
        name,
        phoneNo,
        avatar,
        businessName, // 13
        address,
        industryTypeName,
        roleName,
        roleId,
      } = userStore.user;
      state.formParams = {
        currentName: name,
        userId: id,
        userName: name,
        phoneNum: phoneNo,
        avatar,
        businessNameBake: businessName, //13
        businessName, //13
        address,
        industryTypeName,
        roleName,
        roleId,
      };
      state.currentAvatar = avatar;
      state.backupAvatar = avatar;
    }
    function getUserInfo() {
      userStore.getUserInfo().finally(() => {
        loadUserInfo();
      });
    }

    function updateUser() {
      let { userId, userName, phoneNum, avatar, roleId, businessName } =
        state.formParams;
      let params = { userId, userName, phoneNum, avatar, roleId, businessName };
      if (state.backupAvatar === avatar) {
        params = { userId, userName, phoneNum, roleId, businessName };
      }
      apiUpdateUser(params)
        .then((result) => {
          if (result.code === 200) {
            ElMessage({
              message: "编辑成功",
              type: "success",
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          getUserInfo();
        });
    }
    function handleAvatarSuccess(response) {
      if (response.code === 200) {
        state.formParams["avatar"] = response.data.url;
        updateUser();
        proxy.$refs["avatarUpload"].clearFiles();
      } else {
        proxy.$refs["avatarUpload"].clearFiles();
        ElMessage({
          message: response.msg,
          type: "error",
        });
      }
    }
    function handleAvatarError() {
      proxy.$refs["avatarUpload"].clearFiles();
    }
    const beforeUpload = (file) => {
      let config = state.acceptAvatar;
      let isType = config.types.indexOf(file.type) === -1 ? false : true;
      let isLtSize = (file.size * 1.0) / Math.pow(1024, 2) < config.size;
      if (!isType) {
        ElMessage({
          message: "图片仅支持PNG/JPG格式，请重新上传",
          type: "warning",
        });
        return false;
      } else if (!isLtSize) {
        ElMessage({
          message: "图片大小不能超过" + config.size + "MB" + "，请重新上传",
          type: "warning",
        });
        return false;
      } else return true;
    };
    const onOpen = () => {
      loadUserInfo();
    };
    loadUserInfo();

    return {
      ...toRefs(state),
      onOpen,
      showInputName,
      editInputName,
      beforeUpload,
      handleAvatarSuccess,
      handleAvatarError,
      showInputBusinessName,
      editInputBusinessName,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar-modify {
  height: 0;
  transition: height linear 0.1s;
  &:hover {
    height: 24.5px;
  }
}
.el-avatar:hover + .avatar-modify {
  height: 24.5px;
}
.el-form {
  .el-form-item {
    margin: 0;
  }
}
.user-name {
  height: 32px;
  line-height: 32px;
  display: flex;
  .show-name {
    display: inline-block;
    max-width: 188px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.title {
  display: inline-block;
  width: 60px;
  min-width: 60px;
  text-align: justify;
  text-align-last: justify;
  text-justify: distribute;
}
.user-center {
  .user-box {
    width: 80%;
    margin: auto;
    .font-bold {
      font-weight: 700;
    }
    .text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    .flex {
      display: flex;
    }
    .my-5 {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
    .ml-8 {
      margin-left: 2rem;
    }
    .text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .mr-5 {
      margin-right: 1.25rem;
    }
    .relative {
      position: relative;
    }
    .overflow-hidden {
      overflow: hidden;
    }
    .h-fit {
      height: fit-content;
    }
    .rounded-full {
      border-radius: 9999px;
    }
    .cursor-pointer {
      cursor: pointer;
    }
    .bottom-0 {
      bottom: 0px;
    }
    .absolute {
      position: absolute;
    }
    .w-full {
      width: 100%;
    }
    .text-center {
      text-align: center;
    }
    .items-center {
      align-items: center;
    }
    .title {
      display: inline-block;
      width: 60px;
      min-width: 60px;
      text-align: justify;
      text-align-last: justify;
      text-justify: distribute;
    }
    .user-name {
      height: 32px;
      line-height: 32px;
      display: flex;
    }
    .show-name {
      display: inline-block;
      max-width: 188px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-form-item {
      margin: 0;
    }
    is-required asterisk-left .w-60 {
      width: 15rem;
    }
    .text-gray-600 {
      --tw-text-opacity: 1;
      color: rgb(75 85 99 / var(--tw-text-opacity));
    }
    .bg-gray-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(229 231 235 / var(--tw-bg-opacity));
    }
  }
}
</style>
