<template>
  <div>
    <div>
      <div class="title">
        <SvgIcon icon-class="ask-dingdang" class="ask-icon"></SvgIcon>
        <span class="title-text"> 问询指路 </span>
      </div>
      <div class="content">
        机器人{{ item.snCode }}接收到问路请求，请确认是否处理。
      </div>
    </div>
    <div class="btn-group">
      <div class="custom-button" @click="close">
        <svg-icon icon-class="refuse" />
        <span>拒绝</span>
      </div>
      <div class="custom-button" @click="confirm">
        <svg-icon icon-class="accept" />
        <span>接受</span>
      </div>
    </div>
  </div>
</template>

<script setup name="AskForDirection">
const emit = defineEmits(["cancel", "confirm"]);
const props = defineProps({
  item: {
    type: Object,
    default: {},
  },
});

const close = () => {
  emit("cancel");
};
const confirm = () => {
  emit("confirm");
};
</script>
<style scoped lang="scss">
.title {
  width: 100%;
  display: flex;
  align-items: center;

  .ask-icon {
    font-size: 24px;
    margin-right: 5px;
  }

  .title-text {
    font-size: 16px;
  }
}

.content {
  margin: 20px 0 10px 0;
  width: 100%;
  display: flex;
}

.btn-group {
  display: flex;
  justify-content: center;
  .custom-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }

    & + .custom-button {
      margin-left: 50px;
    }
    span {
      font-size: 12px;
    }
    .svg-icon {
      font-size: 36px;
    }
  }
}
</style>
