<template>
  <div></div>
</template>

<script setup name="GlobalMessage">
import { ElNotification, ElMessageBox } from "element-plus";
import { Upload } from "@element-plus/icons-vue";
import XJWarning from "./xjWarning";
import AskForDirection from "./askForDirection";
import { useWsStore } from "@/store/modules/websocket";
import { useScreenStore } from "@/store/modules/screen";

import { h, onMounted, ref } from "vue";
import { headers } from "@/config/constant";
import { relieveAlarm } from "@/api/xunjian";
import { message } from "@/utils/message";
import { emitter } from "@/utils/mitt";
import { useRoute, useRouter } from "vue-router";

const wsStore = useWsStore();
const route = useRoute();
const router = useRouter();
const screenStore = useScreenStore();
// 告警提示-start
let currentNotifyId = null;
let warnNotifyInstance = null;
const getWarningMessage = (warning = {}) => {
  warnNotifyInstance && warnNotifyInstance.close();
  warnNotifyInstance = ElNotification({
    dangerouslyUseHTMLString: true,
    // message: 'dafjdjfjdkajf',
    message: h(XJWarning, {
      class: "xj-warning-content",
      warning: warning,
      onCancel: () => {
        unbindConfirm(warning);
      },
      onConfirm: () => {
        warnNotifyInstance.close();
        window.open(
          headers["xunjian"] + "/alarmRecord/list?status=0",
          "_blank"
        );
      },
    }),
    onClose() {
      sendClearWarning();
    },
    class: "xj-warning-box",
    duration: 0,
  });
};
const handleAskForDirectionEventIds = ref([]);
const sendUnbind = (warning) => {
  let { id } = warning;
  return relieveAlarm({ ids: [id], isAll: 0 }).then((res) => {
    message("解除成功", { type: "success" });
  });
};
const unbindConfirm = (warning) => {
  ElMessageBox.confirm("确定要解绑该条警报？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
    beforeClose: (action, instance, done) => {
      if (action === "confirm") {
        sendUnbind(warning);
        done();
        warnNotifyInstance.close();
      } else {
        done();
      }
    },
  })
    .then(() => {})
    .catch(() => {});
};
const sendClearWarning = () => {
  let str = JSON.stringify({
    type: "RELEASE_LAST_WARNING",
  });
  wsStore.websocket.send(str);
};
// getWarningMessage();
// 告警提示-end
const watchMessage = () => {
  wsStore.websocket.addEventListener("message", (e) => {
    let res = JSON.parse(e.data);
    let { type, data } = res;
    if (type === "LAST_WARNING") {
      if (!currentNotifyId && data && data.id) {
        currentNotifyId = data.id;
        getWarningMessage(data);
      }
    } else if (type === "ROBOT_VOICE_INTERCOM_PUSH") {
      //问询指路
      data = data.filter((item) => item.status == 1);
      data = data.filter(
        (item) => !handleAskForDirectionEventIds.value.includes(item.eventId)
      );
      handleAskForDirectionsMessage(data);
    }
  });
};
const refuseAskForDirections = (item) => {
  let str = JSON.stringify({
    eventId: item.eventId,
    type: "ROBOT_VOICE_INTERCOM_OPERATE",
    status: 3,
    snCode: item.snCode,
  });
  wsStore.websocket.send(str);
};
const acceptAskForDirections = (item) => {
  let str = JSON.stringify({
    eventId: item.eventId,
    type: "ROBOT_VOICE_INTERCOM_OPERATE",
    status: 2,
    snCode: item.snCode,
  });
  wsStore.websocket.send(str);
  if (route.name == "Screen") {
    emitter.emit("askForDirection");
  } else {
    router.push({
      name: "Screen",
      query: {
        askForDirection: item.snCode,
      },
    });
  }
};
const handleAskForDirectionsMessage = (data) => {
  handleAskForDirectionEventIds.value =
    handleAskForDirectionEventIds.value.concat(
      data.map((item) => item.eventId)
    );

  for (let i = 0; i < data.length; i++) {
    let item = data[i];

    setTimeout(() => {
      let notifyInstance = ElNotification({
        dangerouslyUseHTMLString: true,
        showClose: false,
        class: "ask-direction-notice",
        message: h(AskForDirection, {
          class: "",
          item: item,
          onCancel: () => {
            refuseAskForDirections(item);
            notifyInstance && notifyInstance.close();
          },
          onConfirm: () => {
            // warnNotifyInstance.close();
            // window.open(
            //   headers["xunjian"] + "/alarmRecord/list?status=0",
            //   "_blank"
            // );
            acceptAskForDirections(item);
            notifyInstance && notifyInstance.close();
          },
        }),
        onClose() {
          // sendClearWarning();
        },
        duration: 60 * 1000,
      });
    }, i * 1000);
  }
};
emitter.on("wsReconnected", watchMessage);
watchMessage();
</script>
<style lang="scss">
.el-notification {
  // width: 500px;
  &.xj-warning-box {
    width: initial;
  }

  &.ask-direction-notice {
    padding: 14px;
  }
}
</style>
